import React from "react"
import Layout from "@components/kaigai-fx/layout";
import 'tw-elements';

// 関数呼び出し
import { TermComponent } from "@components/kaigai-fx/term-component.js";

const TermPageM = () => {
  // 設定項目 ================================================================================================
  const myLine = 'ま行'
  const myID = 'ma_'
  const pathName = 'term-ma';
  const termItem = [ // 改行するときは<br>を追加
  {
    Title:'窓',
    Content:'窓とはローソク足とローソク足が途切れたの間に空間のことを指します。<br> 窓が発生するタイミングではトレードを避けた方が良く、「月曜日の朝」等は要注意です。<br> また、上窓はローソク足が間隔を開けて上方向に離れた状態を、<br>下窓は上窓と逆に下落してローソク足が離れてしまっている状態を指します。'
  }
  ]
  // =================================================================================================================================
  // term-component.jsにて詳細設定
  let termPageContent = TermComponent( myLine, myID, termItem, pathName ); 
  
  return (
  <>
    <Layout>
      {termPageContent}
    </Layout>
  </>
  )
}
export default TermPageM